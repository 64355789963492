import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogLatest from "../components/blogLatest"

const IndexPage = () => (
  <Layout>
    <div>
      <div className="row">
        <div className="eight columns">
          <h4>Latest Blog Entry</h4>
          <BlogLatest>
          </BlogLatest>
        </div>
        
        <div className="four columns">
          <h4>About</h4>
          <p>
            Hi, I am .net developer, development manager and software craftsman in
            Manchester. This is a place to store my thoughts.
          </p>
          <p>
            These thoughts, writings and opinions are mine and not my employers'
            or any previous employers'.
          </p>
          <div className="sidebar-module">
            <h4>Elsewhere</h4>
            <ol className="list-unstyled">
              <li>
                <a
                  href="https://github.com/davelobban?tab=repositories"
                  target="_blank"
                  rel="noreferrer"
                >
                  GitHub
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/dave-lobban-6422a161/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Linked In
                </a>
              </li>
              <li>
                <a
                  href="https://stackoverflow.com/users/1412915/badgerspot"
                  target="_blank"
                  rel="noreferrer"
                >
                  Stack Overflow
                </a>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <SEO title="Home" />
  </Layout>
)

export default IndexPage
